@mixin applyTheme($themeColor) {
  	.page-item.active .page-link{
		background-color: $themeColor;
		border-color: $themeColor;
	}
	.page-link{
		color: $themeColor;
		&:focus{
			box-shadow: none;
		}
	}
	.form-control:focus{
		box-shadow: none;
		border-color: $gray-400;
	}
	select.form-control {
		option {
			&:hover, &:active{
				background-color: $themeColor;
				color: $white;
			}
		}
	}
}

.theme-primary{
	@include applyTheme($primary);
}

.theme-info{
	@include applyTheme($info);
}

.theme-secondary{
	@include applyTheme($secondary);
}

.theme-success{
	@include applyTheme($success);
}

.theme-warning{
	@include applyTheme($warning);
}

.theme-danger{
	@include applyTheme($danger);
}

.theme-dark{
	@include applyTheme($dark);
}